<template>
    <div class="py-4 container-fluid">
        <div class=" row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header pb-0">
                        <h6 @click="login">Students</h6>
                    </div>
                    <!-- Button trigger modal -->
                    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        Launch demo modal
                    </button>

                    <!-- Modal -->
                    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                        aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h1 class="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    ...
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    <button type="button" class="btn btn-primary">Save changes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body px-0 pt-0 pb-2">
                        <div class="table-responsive p-0">
                            <table class="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name
                                        </th>
                                        <th
                                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                            Group</th>
                                        <th
                                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                            Contact</th>
                                        <th
                                            class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                            Status</th>
                                        <!-- <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Employed</th> -->
                                        <!-- <th class="text-secondary opacity-7"></th> -->
                                    </tr>
                                </thead>
                                <tbody>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-4 row">
            <div class="col-12">
            </div>
        </div>
    </div>
</template>
  
<script>
import axios from 'axios';
export default {
    components: {
    },
    data() {
        return {
            students: [],
        }
    },
    mounted() {
        // axiosConfig.get("back/students/list").then(resp => {
        //     this.students = resp.data.students;
        //     console.log(resp)
        // })
        console.log("Informations")
    },
    methods: {
        login() {
            // axios.post('http://localhost:8000/api/secret/login', {
            //     email : "itsmeniraj@softworica.com",
            //     password : "password"
            // }).then(resp => {
            //     console.log(resp)
            // })
            var token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiNzI5NGQ3MTdmNjAwYjNhNzUzMzRhZDFmMjc5ODFmYjdhNjAzNjkxNGFmNThiZmNjMTA0Y2Q1ZjQ4NDNiZGViY2RiMWQ5Y2JlZTE3ZTE3MWEiLCJpYXQiOjE3MDQ4MjU0NTIuOTMyOTgzLCJuYmYiOjE3MDQ4MjU0NTIuOTMyOTgzLCJleHAiOjE3MzY0NDc4NTIuOTI3OTg5LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.MH0PxdlU4SL2COdWFBNbBuGbuSAhPW8oDQ2Znf3Sm_mX25krjiCESBI-kx3jxoh3O1Ql2iPoBhslYtyZ6iEb1Z4AWAINDd3OsVnww0T1LZnvmeexI5-HzqJxiR-Ue0XzzjdMZ5jIwvoDxJTul8NhTcyUe0mfqefpjH8xHAuaJ2VLkTjtV7PFWg_vYpSzjmW426GtJ7ZkDX5hAImJcA-9qiQ12WMalQ7TA_rqlNTBllssaAsjA75npu_zOJfZXRq8ENJoOPwFthxH8jkj4NmoAZslaohr_gA6Z6dpxJQIAvwtzyIREYC2Btxr2kxPcJnibZOEaP0L922uewY4W3gkJ5rS9YdKiapAT5GB-gUYrrZrD-kUorUezI0TL1zlzaUywvSp4HL_QMnP46jMPZTSMlV3pTK_EgtlOCXSwCUwhnFTZRo2f8EmYIGJQTn-8_2PEkphg6qbj1WBCSSt_6KuPbenNrFVRfjiWML34D9hSr_BUL054xttqJEAh4c0chGskUeQAlBjiw37gyS9WHBmIEQibjyQ5YKiqaAYWoyi70iB54Iaf1s3LLWzgcLfFlolaz37hzfpajJqalLa6BTouil-YA1fB13Dt4OnFg4KHMb3hrQdcT1DpbulDSrpS_Gmk-yMqVIwcV93QUN76QFsnWQf1O_n0j60itkpFbvsaLo";
            // axios.defaults.headers.common["Authorization"] = `Bearer ${token}`

            axios.get('http://localhost:8000/oauth/clients', {
                headers: {
                    "Content-Type": 'application/x-www-form-urlencoded',
                    "Authorization": `Bearer ${token}`
                }
            })
                .then(response => {
                    console.log(response.data);
                });

        }
    }

};
</script>
  